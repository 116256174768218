import PropTypes from "prop-types";
import { lazy, Suspense } from "react";
import { Card, Divider, Box, Typography, Grid /* , Paper */ } from "@mui/material";
import "./EspeceCountCard.css";
import SuspenceComponent from "components/SuspenceComponent";

const CustomProgressBar = lazy(() => import("layouts/dashboard/components/CustomProgressBar"));

function EspeceCountCard({ especeCountData, isEffectifPrev }) {
  const calculatePercentage = (reel, previsionnel) => {
    if (previsionnel === 0) {
      return 0;
    }
    const perc = (reel / previsionnel) * 100;
    if (perc >= 100) {
      return 100;
    }
    return perc.toFixed(2);
  };
  const pourcentage = calculatePercentage(
    especeCountData?.realCount ?? 0,
    especeCountData?.prevCount ?? 0
  );
  // console.log("dddd//", especeCountData?.label, especeCountData.img);
  return (
    <Suspense fallback={<SuspenceComponent />}>
      <Card className="custom-grid-espece-count-cards" key={especeCountData?.id}>
        <Box className="custom-grid-espece-count-cards">
          <Box display="flex" flexDirection="row">
            <Box className="custom-box-espece-counts">
              <img src={especeCountData?.img} className="img-fluid" alt="Beta Click" />
            </Box>

            <Box className="custom-espece-count-text-containers" sx={{ marginLeft: 2 }}>
              <Typography className="custom-espece-count-headings " style={{ fontWeight: "bold" }}>
                {especeCountData?.label ?? ""}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Grid container className="custom-grid-espece-count-containers">
            <Grid item className="custom-grid-espece-count-items" xs={6}>
              <Typography style={{ fontSize: 15 }} className="custom-grid-espece-count-typographys">
                Effectif Prévisionnel{" "}
              </Typography>
              <Typography
                style={{ fontWeight: "bold" }}
                className="custom-grid-espece-count-typographys"
              >
                {especeCountData?.prevCount ?? 0}
              </Typography>
            </Grid>

            {isEffectifPrev && (
              <Grid item xs={6} className="custom-grid-espece-count-items">
                <Typography
                  style={{ fontSize: 15 }}
                  className="custom-grid-espece-count-typographys"
                >
                  Effectif Réel{" "}
                </Typography>
                <Typography
                  style={{ fontWeight: "bold" }}
                  className="custom-grid-espece-count-typographys"
                >
                  {especeCountData?.realCount ?? 0}
                </Typography>
              </Grid>
            )}
          </Grid>
          {isEffectifPrev && <CustomProgressBar progress={pourcentage} />}
        </Box>
      </Card>
    </Suspense>
  );
}

EspeceCountCard.defaultProps = {
  especeCountData: {},
  isEffectifPrev: false,
};

EspeceCountCard.propTypes = {
  especeCountData: PropTypes.object,
  isEffectifPrev: PropTypes.bool,
};

export default EspeceCountCard;
