import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import EngineeringIcon from "@mui/icons-material/Engineering";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockResetIcon from "@mui/icons-material/LockReset";
// import InfoIcon from "@mui/icons-material/Info";
import { Box, Button, Switch, Tooltip } from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import cow from "assets/images/cow.png";
// import lot from "assets/images/lot.png";
import animal from "assets/images/icons/iconFerme/animal.svg";
import lot from "assets/images/icons/iconFerme/lot.svg";

import {
  ACTION_RESET,
  ACTION_ANIMAL,
  ACTION_ROLE,
  ACTION_ETAT,
  ACTION_EDIT,
  ACTION_DETAIL,
  ACTION_LOT,
} from "constant/dataTableActionConstant";

export default function DataTableAction({ handleAction, item }) {
  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: `${item.enabled ? "green" : "red"}`,
          },
          colorPrimary: {
            "&.Mui-checked": {
              // color: item.etatColor,
              color: "green",
            },
          },
          track: {
            opacity: 0.5,
            ".Mui-checked.Mui-checked + &": {
              opacity: 0.7,
              backgroundColor: `${item.enabled ? "green" : "red"}`,
            },
          },
        },
      },
    },
  });
  /* let checkedUser = true;
  if (!item.enabled) {
    checkedUser = false;
  } */
  return (
    <Box textAlign="center" sx={{ display: "flex", flexDirection: "row" }}>
      {item.isEdit && (
        <Tooltip title="Modifier" arrow>
          <IconButton onClick={() => handleAction(item, ACTION_EDIT)} size="small">
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {item.isRole && (
        <Tooltip title="Role" arrow>
          <IconButton onClick={() => handleAction(item, ACTION_ROLE)} size="small">
            <EngineeringIcon />
          </IconButton>
        </Tooltip>
      )}
      {item.isDetail && (
        <Tooltip title="Voir plus" arrow>
          <IconButton onClick={() => handleAction(item, ACTION_DETAIL)} size="small">
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      )}
      {item.isReset && (
        <Tooltip title="Réinitialiser le mot de passe" arrow>
          <IconButton onClick={() => handleAction(item, ACTION_RESET)} size="small">
            <LockResetIcon />
          </IconButton>
        </Tooltip>
      )}
      {item.isUser && (
        <Tooltip title="Bloquer/Activer" arrow>
          <Box>
            <ThemeProvider theme={theme}>
              <Switch
                checked={item.enabled}
                size="small"
                onChange={() => handleAction(item, ACTION_ETAT)}
                // disabled={checkedUser}
              />
            </ThemeProvider>
          </Box>
        </Tooltip>
      )}
      {item.isAnimal && (
        <Tooltip title="Voir les animaux" arrow>
          <Button onClick={() => handleAction(item, ACTION_ANIMAL)}>
            <img src={animal} alt="animals" width="20px" />
          </Button>
        </Tooltip>
      )}
      {item.isLot && (
        <Tooltip title="Voir les lots" arrow>
          <Button onClick={() => handleAction(item, ACTION_LOT)}>
            <img src={lot} alt="animals" width="20px" />
          </Button>
        </Tooltip>
      )}
    </Box>
  );
}
DataTableAction.propTypes = {
  handleAction: PropTypes.func,
  item: PropTypes.object,
};
DataTableAction.defaultProps = {
  handleAction: () => {},
  item: {},
};
