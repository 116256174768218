// src/UserProfile.js
import React, { useContext } from "react";
import { Grid } from "@mui/material";
import UserDetails from "layouts/gestionUsers/component/UserDetails";
import UserRole from "layouts/gestionUsers/component/UserRole";
import { UserContext } from "context/userContext";

const UserProfile = () => {
  const { user } = useContext(UserContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UserDetails />
      </Grid>
      {user && (
        <Grid item xs={12}>
          <UserRole />
        </Grid>
      )}
    </Grid>
  );
};

export default UserProfile;
