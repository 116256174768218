import React, { useContext } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import ExcelExport from "utils/excelExport";
import MDBox from "components/MDBox";
import { LieuContext } from "context/AppContext";
import MDButton from "components/MDButton";

export default function EffectifPrevAction() {
  const lieuContext = useContext(LieuContext);
  const { openAction, handleCloseAction, handleOpen, exportedData } = lieuContext;
  // // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleOpenForm = () => {
    handleCloseAction();
    handleOpen(true);
  };
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={openAction}
      onClose={handleCloseAction}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Ajouter des effectifs Prévisionnels</DialogTitle>
      <DialogContent>
        <DialogContentText>Effectifs prévisionnels par espèce et par lieu</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton
          type="submit"
          variant="gradient"
          color="info"
          onClick={() => handleOpenForm()}
          style={{ marginRight: "10px", marginTop: 15 }}
        >
          Créer un effectif Prévisionnel
        </MDButton>
        <MDBox display="flex" alignItems="center" sx={{ marginRight: 0 }}>
          <ExcelExport
            exportedData={exportedData}
            fileName="Modèle effectif Prévisionnel"
            btnTitle="Exporter le Modèle"
          />
        </MDBox>
      </DialogActions>
    </Dialog>
  );
}
