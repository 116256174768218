import React, { useEffect, useState } from "react";
import { TextField, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import useTableUser from "layouts/gestionUsers/hooks/useTableUser";
import { getUserEntity, handleSearchUser } from "services/userService";
// import { getAnimalEntity } from "services/entityService";
// import useTableAnimaux from "layouts/gestionAnimaux/hooks/useTableAnimaux";
import DataTable from "examples/Tables/DataTable";
import {
  TYPE_DIRECTION,
  TYPE_STRUCTURE_PRIVEE,
  TYPE_STRUCTURE_PRIVEE_VALUE,
} from "layouts/donneeReference/rubrique/gestionEntite/constant";

const EntiteInfo = ({ getData, selectedData, handleLoad }) => {
  const { columns, rows, renderDataTable } = useTableUser();
  // const { columnsAnimaux, rowsAnimaux, renderDataTableAnimaux } = useTableAnimaux();
  const [listUser, setListUser] = useState([]);
  // const [listAnimaux, setListAnimaux] = useState([]);

  /*  const fetchAnimalData = async () => {
    const data = await getAnimalEntity(
      getData,
      `animals/entite/${selectedData?.superViseur?.numTel}`
    );

    setListUser(data);
    renderDataTableAnimaux(data, false);
  }; */
  const fetchUserData = async () => {
    const data = await getUserEntity(getData, selectedData?.name);
    setListUser(data);
    renderDataTable(data, false);
  };
  const handleSearch = async (value) => {
    const data = await handleSearchUser(listUser, handleLoad, value);
    setListUser(data);
    renderDataTable(data);
  };

  useEffect(() => {
    fetchUserData();

    // fetchAnimalData();
  }, []);
  return (
    <>
      <Box
        sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
        style={{ backgroundColor: "white" }}
      >
        <Box textAlign="center">
          <Typography variant="h6">Détails </Typography>
        </Box>

        <TextField
          label="Nom *"
          name="nom"
          value={selectedData?.name ?? "Non renseigné"}
          // onChange={handleInputChange}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Type *"
          name="type"
          value={
            selectedData?.type === TYPE_STRUCTURE_PRIVEE
              ? TYPE_STRUCTURE_PRIVEE_VALUE
              : TYPE_DIRECTION
          }
          // onChange={handleInputChange}
          fullWidth
          margin="normal"
          disabled
        />
        <TextField
          label="Superviseur"
          name="nom"
          value={`${selectedData?.superViseur?.prenom ?? ""} ${
            selectedData?.superViseur?.nom ?? ""
          } / ${selectedData?.superViseur?.numTel ?? ""}`}
          // onChange={handleInputChange}
          fullWidth
          margin="normal"
          disabled
        />
      </Box>
      {/* <Box pt={3}>
        <Box textAlign="center">
          <Typography variant="h6">Animaux </Typography>
        </Box>

        <DataTable
          table={{ columnsAnimaux, rowsAnimaux }}
          isSorted={false}
          entriesPerPage
          showTotalEntries
          noEndBorder
          canSearch
          handleSearch={handleSearch}
          refreshData={fetchAnimalData}
        />
      </Box> */}
      <Box pt={3}>
        <Box textAlign="center">
          <Typography variant="h6">Utilisateurs </Typography>
        </Box>

        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage
          showTotalEntries
          noEndBorder
          canSearch
          handleSearch={handleSearch}
          refreshData={fetchUserData}
        />
      </Box>
    </>
  );
};

export default EntiteInfo;
EntiteInfo.propTypes = {
  selectedData: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  handleLoad: PropTypes.func.isRequired,
};
//   EntiteInfo.defaultProps = {
//     selectedData: "",
//   };
