import { Box, Typography } from "@mui/material";
import React from "react";
import colors from "assets/theme/base/colors";
import PropTypes from "prop-types";

function ModuleTitle({ title }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography
        display="block"
        variant="h4"
        fontWeight="medium"
        style={{ color: colors.success.main }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default ModuleTitle;
ModuleTitle.propTypes = {
  title: PropTypes.string,
};
ModuleTitle.defaultProps = {
  title: "",
};
