import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import MDBox from "components/MDBox";

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // -1 navigates to the previous page
  };

  return (
    <MDBox
      display="flex"
      alignItems="center"
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <IconButton onClick={handleBackClick}>
        <ArrowBackIcon fontSize="small" style={{ color: "gray" }} />
      </IconButton>
    </MDBox>
  );
};

export default BackButton;
