import useArrayOperation from "hooks/useArrayOperation";
import { ERROR, NO_DATA_FOUND } from "constant/message";
import { MODULE_USER } from "constant/moduleInfo";
import { normalizeText } from "utils/textOperation";

const { customArrItem, sortArray } = useArrayOperation();

const getUserbyRole = async (getDataUser, listTargetRole) => {
  const dataByRole = await Promise.all(
    listTargetRole.map(async (i) => {
      const arrayData = await getDataUser(`Users/byRole/${i.role}`);
      return arrayData;
    })
  );
  const fetchData = dataByRole.flat();
  let dataUser = [];
  if (fetchData !== ERROR && fetchData?.some((i) => i !== ERROR)) {
    if (Array.isArray(fetchData) && fetchData.length > 0) {
      const customArr = customArrItem(fetchData, MODULE_USER);
      dataUser = sortArray(customArr, "userName");
    }
  }
  return dataUser;
};
export default getUserbyRole;
export const searchUser = async (postData, numTel) => {
  const searchValue = [
    {
      field: "numTel",
      searchTerm: numTel,
      queryType: "Text",
    },
  ];
  const fetchData = await postData("search/index/users", searchValue);
  let result;
  if (fetchData !== ERROR) {
    result = fetchData;
  }
  return result;
};
export const getUserEntity = async (getData, entiteName) => {
  const fetchData = await getData(`Users/entite/${entiteName}`);

  let result = [];
  // console.log("dddddd", fetchData);
  if (fetchData !== ERROR) {
    const dataUser = fetchData.map((i) => {
      const item = {
        ...i,
        ...{ userName: `${i.prenom} ${i.nom}` },
      };
      return item;
    });
    result = sortArray(dataUser, "name");
  }
  return result;
};
export const handleSearchUser = (listUser, handleLoad, value) => {
  let result;
  if (value === "") {
    result = listUser;
  }
  const searchTerm = normalizeText(value);

  const filteredData = listUser.filter((item) => {
    return (
      normalizeText(item?.prenom).includes(searchTerm) ||
      normalizeText(item?.nom).includes(searchTerm) ||
      normalizeText(item?.numTel).includes(searchTerm) ||
      normalizeText(item?.lieu?.region).includes(searchTerm) ||
      normalizeText(item?.lieu?.departement).includes(searchTerm) ||
      normalizeText(item?.lieu?.commune).includes(searchTerm) ||
      normalizeText(item?.fonction).includes(searchTerm) ||
      item?.roles?.some((i) => normalizeText(i.roleName).includes(searchTerm))
    );
  });
  if (filteredData.length > 0) {
    result = filteredData;
  }
  const loadData = {
    isAlert: true,
    message: NO_DATA_FOUND,
    isLoading: false,
    alertSeverity: "error",
  };
  handleLoad(loadData);
  return result;
};
