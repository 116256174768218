const handleSelectChange = (initialData, nameInput, valueInput) => {
  const updateValue = { ...initialData };
  // console.log("nameInput", nameInput);
  // console.log("valueInput", valueInput);
  const defaultSelect = { label: "" };
  if (valueInput.reason === "clear") {
    updateValue[nameInput] = defaultSelect;
  } else if (valueInput.isResultObject) {
    updateValue[nameInput] = valueInput.newValue;
  } else {
    updateValue[nameInput] = valueInput.newValue.label;
  }

  return updateValue;
};
export default handleSelectChange;
