import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AnimalPerson from "layouts/gestionAnimaux/component/AnimalPerson";
import { Grid, Box, Typography, TextField } from "@mui/material";
import EspeceCountCard from "components/EspeceCountCard";
import useArrayOperation from "hooks/useArrayOperation";

const DemandeIdentificationDetailsComponent = ({ demande }) => {
  const { sortArray } = useArrayOperation();
  const person = {
    type: "Demandeur",
    info: demande.user,
  };
  const [listeEspece, setListeEspece] = useState([]);
  useEffect(() => {
    // setData();
    const result = sortArray(demande?.effectifs ?? [], "nomCourant");
    setListeEspece(result);
  }, [demande]);
  return (
    <>
      {/* <Grid container spacing={2} justifyContent="centers" alignItems="centers" direction="columns"> */}
      <AnimalPerson person={person} />
      <RecapDemande demande={demande} />
      <Grid
        container
        spacing={4}
        sx={{ mt: 5 }}
        justifyContent="center"
        alignItems="center"
        // direction="column"
      >
        {listeEspece?.map((i) => (
          <Grid item xs={12} sm={6} md={4} key={i.espece?.nomCourant}>
            <EspeceCountCard especeCountData={i} />
          </Grid>
        ))}
      </Grid>
      {/* </Grid> */}
    </>
  );
};
const RecapDemande = ({ demande }) => {
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Typography variant="h6">Etat de la demande</Typography>
      <TextField
        label="Date de la demande"
        name="date"
        value={demande?.date ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Effectif Total"
        name="effectifTotal"
        value={demande?.effectifTotal ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Satut"
        name="status"
        value={demande?.status ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
    </Box>
  );
};

export default DemandeIdentificationDetailsComponent;
DemandeIdentificationDetailsComponent.propTypes = {
  demande: PropTypes.object.isRequired,
};
RecapDemande.propTypes = {
  demande: PropTypes.object.isRequired,
};
