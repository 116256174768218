export const normalizeText = (text) => {
  // console.log("sssss///", text);
  return (
    text
      ?.normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase() || ""
  );
};

export const tableCellTextFormat = (text, length) => {
  return text?.length >= length ? `${text.substring(0, length)}...` : text || "";
};
export const isNumeric = (str) => {
  // return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str) && !isNaN(parseFloat(str));
};
