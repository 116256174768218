import React, { useContext } from "react";
import { TextField, Box, Typography, Divider } from "@mui/material";
import { AnimalContext } from "context/animalContext";
import dateFormat from "utils/dateFormat";

const AnimalDetails = () => {
  const { animal, title } = useContext(AnimalContext);

  // console.log("ddd///", animal);
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Fiche de l&apos;animal {title ?? ""}</Typography>
      </Box>
      <TextField
        label="Référence de l'Animal"
        name="refAnimal"
        value={animal?.refAnimal ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="nom"
        label="Nom de l'Animal"
        value={animal?.nom && animal.nom.trim() !== "" ? animal.nom : "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="sexe"
        label="Sexe"
        value={animal?.sexe === "FEMALE" ? "FEMELLE" : animal?.sexe}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="espece"
        label="Espece"
        value={animal?.espece?.nomCourant ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="race"
        label="Race"
        value={animal?.race?.nomCourant ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="poids"
        label="Poids"
        value={animal?.poids ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="taille"
        label="Taille"
        value={animal?.taille ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="robe"
        label="Robe"
        value={animal?.robe && animal.robe.trim() !== "" ? animal.robe : "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="lot"
        label="Lot"
        // value={animal?.lot?.nomLot ?? "Non renseigné"}
        value={
          animal?.lot?.nomLot && animal.lot?.nomLot.trim() !== ""
            ? animal.lot?.nomLot
            : "Non renseigné"
        }
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="ferme"
        label="Exploitation"
        // value={animal?.lot?.nomLot ?? "Non renseigné"}
        value={
          animal?.lot?.ferme?.nom && animal.lot?.ferme?.nom.trim() !== ""
            ? animal.lot?.ferme?.nom
            : "Non renseigné"
        }
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />

      <TextField
        name="dateNaissance"
        label="Date de naissance"
        value={dateFormat(new Date(animal?.dateNaissance)) ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="dateInsert"
        label="Date d'identification"
        value={dateFormat(new Date(animal?.dateInsert)) ?? "Non renseigné"}
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        name="projet"
        label="Projet"
        // value={animal?.animalTags?.join(", ") ?? "Non renseigné"}
        value={
          animal?.animalTags && animal?.animalTags.length > 0
            ? /* animal?.animalTags
                ?.map((i) => i.tag)
                .map((i) => i.name)
                .join(", ") */
              animal?.animalTags?.map((i) => i.tag?.name).join(", ") || ""
            : "Non renseigné"
        }
        // onChange={handleInputChange}
        fullWidth
        margin="normal"
        disabled
      />
      <Divider textAlign="center" />
      <Box textAlign="center">
        <Typography variant="h6">Lieu d&apos;Identification</Typography>
      </Box>

      <TextField
        label="Commune"
        name="commune"
        value={animal?.lieuIdentification?.commune ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Département"
        name="departement"
        value={animal?.lieuIdentification?.departement ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Région"
        name="region"
        value={animal?.lieuIdentification?.region ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
    </Box>
  );
};

export default AnimalDetails;
