import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

export default function DataTableContent({ title1, title2, title3 }) {
  return (
    <Box display="flex" alignItems="center" lineHeight={1}>
      <Box ml={2} lineHeight={1}>
        <Typography display="block" variant="button" fontWeight="medium">
          {title1}
        </Typography>
        {title2 !== "" && (
          <Typography variant="button" display="block">
            {title2}
          </Typography>
        )}
        {title3 !== "" && <Typography variant="caption">{title3}</Typography>}
      </Box>
    </Box>
  );
}
DataTableContent.propTypes = {
  title1: PropTypes.string,
  title2: PropTypes.string,
  title3: PropTypes.string,
};
DataTableContent.defaultProps = {
  title1: "",
  title2: "",
  title3: "",
};
