const dataStatutDemandeIdentification = [
  { label: "INITIALISÉ", name: "INITIALIZED", bgColor: "gray" },
  { label: "ACCEPTÉ", name: "ACCEPTED", bgColor: "blue" },
  { label: "REJETÉ", name: "REJECTED", bgColor: "red" },
  { label: "FAIT", name: "DONE", bgColor: "purple" },
  { label: "VALIDÉ", name: "VALIDATED", bgColor: "#FFC300" },
  { label: "RÉCLAMÉ", name: "RECLAMED", bgColor: "orange" },
  { label: "CLOTURÉ", name: "CLOSED", bgColor: "green" },
];
export default dataStatutDemandeIdentification;
