import { ERROR } from "constant/message";
import { MODULE_ESPECE } from "constant/moduleInfo";

import useArrayOperation from "hooks/useArrayOperation";

const { sortArray } = useArrayOperation();
const getEspece = async (getData) => {
  const fetchData = await getData(MODULE_ESPECE);
  let result = [];
  if (fetchData !== ERROR) {
    result = sortArray(fetchData, "nomCourant");
  }
  return result;
};
export default getEspece;
