import { ERROR } from "constant/message";
import { MODULE_ENTITE } from "constant/moduleInfo";
import useArrayOperation from "hooks/useArrayOperation";

const { sortArray } = useArrayOperation();
const getEntity = async (getData) => {
  const fetchData = await getData(MODULE_ENTITE);
  // const targetData = fetchData.filter((i) => i.type === typeEntite);
  let result = [];
  if (fetchData !== ERROR) {
    result = sortArray(fetchData, "name");
  }
  return result;
};
export const getAnimalEntity = async (getData, endpoint) => {
  const fetchData = await getData(endpoint);
  // const targetData = fetchData.filter((i) => i.type === typeEntite);
  let result = [];
  if (fetchData !== ERROR) {
    result = sortArray(fetchData, "refAnimal");
  }
  console.log("ddd", result);
  return result;
};

export default getEntity;
