import userInfos, { isAdminDepartemental, checkRoleExist } from "utils/userInfos";
import useArrayOperation from "hooks/useArrayOperation";
import { MODULE_LIEU } from "constant/moduleInfo";
import { ERROR } from "constant/message";
import { CODE_PAYS } from "constant";
import { ADMIN_REGION_ROLE } from "constant/role";

const { removeDuplicateItem } = useArrayOperation();
const getLieu = async (getData) => {
  const fetchData = await getData(MODULE_LIEU);
  let lieuClassified = {};
  if (fetchData !== ERROR) {
    const dataLieu = fetchData.filter((i) => i.codeRegion !== null && i.codePays === CODE_PAYS);
    const canAddbyAdminDepartement = isAdminDepartemental();
    const canAddbyAdminRegion = checkRoleExist(ADMIN_REGION_ROLE);
    let relatedLieu = dataLieu;
    const userData = userInfos();
    if (canAddbyAdminDepartement) {
      //  relatedLieu = dataLieu.filter((i) => i.codeRegion === userData.lieu.codeRegion);

      relatedLieu = dataLieu.filter((i) => i.departement === userData.lieu.departement);
      // onsole.log("i am here", relatedLieu.length);
    }
    if (canAddbyAdminRegion) {
      relatedLieu = dataLieu.filter((i) => i.codeRegion === userData.lieu.codeRegion);
    }

    const listRegion = removeDuplicateItem(relatedLieu, "region");
    const listDepartement = removeDuplicateItem(relatedLieu, "departement");
    const listCommune = removeDuplicateItem(relatedLieu, "commune");
    lieuClassified = {
      listRegion,
      listDepartement,
      listCommune,
      relatedLieu,
    };

    // console.log("canAddbyAdminRegion", canAddbyAdminRegion);
    // console.log("ddsss", dataLieu[0], userData.lieu);
  }
  return lieuClassified;
};
export default getLieu;
