import React, { useMemo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import EntiteInfo from "layouts/donneeReference/rubrique/gestionEntite/component/EntiteInfo";

function DetailEntite() {
  const { state } = useLocation();
  const { selectedData } = state;
  // console.log("DetailEntite", selectedData);
  const { isAlert, isLoading, message, alertSeverity, handleLoad, getData } = servicesAPI();
  const [title, setTitle] = useState("");
  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  /* const propsDataConfig = useMemo(() => {
    const value = {
      selectedData,
      ApiService: { getData, putData, postData },
      handleLoad,
    };
    return value;
  }, [selectedData]); */
  // console.log("ddd", state);
  useEffect(() => {
    if (selectedData) {
      setTitle(`Informations détaillées`);
    }
  }, [selectedData]);
  return (
    <>
      <ModuleTitle title={title} />
      <BackButton />
      <EntiteInfo getData={getData} selectedData={selectedData} handleLoad={handleLoad} />

      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailEntite;
