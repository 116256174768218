import React, { useContext } from "react";
// import PropTypes from "prop-types";
import ModuleTitle from "components/ModuleTitle";
import { Box } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import Form from "components/Form";
import LayoutContext from "context/LayoutContext";
import { ACTION_ADD } from "constant/dataTableActionConstant";
import {
  MODULE_USER,
  PATH_DETAIL_USER,
  MODULE_DEMANDE_IDENTIFICATION,
  PATH_NEW_DEMANDE_IDENTIFICATION,
} from "constant/moduleInfo";
import { useNavigate } from "react-router-dom";
import BackButton from "components/BackButton";

function indexDataTableComponent() {
  const navigate = useNavigate();
  const layoutContext = useContext(LayoutContext);
  const {
    title,
    permissionModule,
    columns,
    rows,
    handleDialogForm,
    module,
    // actionOption,
    // openAnimalTable,
    onSelectedRow,
    handleSearch,
    refreshData,
    niveau,
    roleTitle,
  } = layoutContext;
  // console.log("componentModule", roleTitle);
  const openForm = () => {
    if (module === MODULE_USER) {
      navigate(PATH_DETAIL_USER, { state: { niveau, roleTitle } });
    } else if (module === MODULE_DEMANDE_IDENTIFICATION) {
      // console.log("ddd here");
      navigate(PATH_NEW_DEMANDE_IDENTIFICATION, { state: { selectedData: {} } });
    } else {
      onSelectedRow({}, ACTION_ADD);
      handleDialogForm(true, ACTION_ADD);
    }
  };
  // console.log("permission module", permissionModule);
  return (
    <div>
      {/* <Typography variant="h2">{title}</Typography> */}
      <ModuleTitle title={title} />
      <BackButton />
      {permissionModule.find((i) => i.name.includes(`Insert${module}`)) && (
        <MDButton
          type="submit"
          variant="gradient"
          color="info"
          sx={{ marginLeft: "auto", marginRight: 10 }}
          // style={{}}
          onClick={openForm}
        >
          Ajouter
        </MDButton>
      )}
      {/* rows.length > 0 && ( */}
      <Box pt={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage
          showTotalEntries
          noEndBorder
          canSearch
          handleSearch={handleSearch}
          refreshData={refreshData}
        />
      </Box>
      {/* }) */}
      <Form />
    </div>
  );
}

const DataTableComponent = React.memo(indexDataTableComponent);
export default DataTableComponent;
