import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  // TableHead,
  TableRow,
  Paper,
  TextField,
  // Button,
} from "@mui/material";
import getUserbyRole from "services/userService";
import PropTypes from "prop-types";
import AutoCompleteComponent from "components/AutoCompleteComponent";
import { roleN5E, roleN5P } from "constant/role";
import handleSelectChange from "helpers/selectHelper";
import getEspece from "services/especeService";
import MDButton from "components/MDButton";
import {
  createDemandeIdentification,
  updateDemandeIdentification,
} from "services/demandeIdentificationService";
import { ERROR } from "constant/message";
import { useNavigate } from "react-router-dom";
import { LAST_PATH } from "constant";
import dataStatutDemandeIdentification from "constant/dataStatutDemandeIdentification";
import userInfos, { isAdminDepartemental, isSuperAdmin } from "utils/userInfos";

import AnimalPerson from "./AnimalPerson";

const NewDemandeIdentificationComponent = ({ propsDataConfig }) => {
  const { ApiService, handleLoad, selectedData } = propsDataConfig;
  const navigate = useNavigate();
  const [listUser, setListUser] = useState([]);
  const [listEspece, setListEspece] = useState([]);
  const [demande, setDemande] = useState(selectedData ?? {});
  const [userInfo, setUserInfo] = useState(selectedData?.user ? { info: selectedData?.user } : {});
  const [statut, setStatut] = useState("");
  const isUpdate = Object.keys(selectedData).length !== 0;
  // const isUpdate = false;
  console.log("sssss", selectedData);
  console.log("sssss//", demande);
  // const statutDemande = dataStatutDemandeIdentification.find((i) => i.name === demande?.status);
  //  const [status, setStatus] = useState(selectedDemande.status ?? "INITIALIZED");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // console.log("dddda", name, value);
    //  const updatedDemande = { ...newDemande, [name]: value };
    let updatedDemande = {};
    if (value.isSelect && name !== "status") {
      // if (["fonction ", "entity", "userTags"].includes(name)) {
      updatedDemande = handleSelectChange(demande, name, value);
    } else if (name === "status") {
      let statutValue;
      let statusName;
      if (value.reason === "clear") {
        statutValue = "";
        statusName = "";
      } else {
        statutValue =
          dataStatutDemandeIdentification.find((i) => i.name === value.newValue.name)?.label ?? "";
        statusName =
          dataStatutDemandeIdentification.find((i) => i.name === value.newValue.name)?.name ?? "";

        // console.log("ddddd", demande);
      }
      updatedDemande = { ...demande, status: statusName };
      setStatut(statutValue);
    } else {
      updatedDemande = { ...demande, [name]: value };
    }
    setDemande(updatedDemande);
    if (name === "user" && value.reason !== "clear") {
      const selectedUser = {
        info: value.newValue,
      };
      setUserInfo(selectedUser);
    }
  };

  const fetchUser = async () => {
    const listTargetRole = [...roleN5E, ...roleN5P];
    const data = await getUserbyRole(ApiService.getDataUser, listTargetRole);
    let filterUser = data.filter((i) => i.enabled);
    if (isAdminDepartemental()) {
      const userData = userInfos();
      filterUser = data.filter(
        (i) => i.enabled && i.lieu.departement === userData.lieu.departement
      );
    }
    setListUser(filterUser);
  };
  const fetchEspece = async () => {
    const data = await getEspece(ApiService.getData);
    const resultEspece = data.map((i) => {
      let count = 0;
      if (isUpdate) {
        count =
          selectedData?.effectifs.find((x) => i.nomCourant === x.espece.nomCourant)?.count ?? 0;
        // console.log("zzz", count);
      }
      return {
        espece: i,
        count,
        // especeId: i.id,
      };
    });
    // console.log("aaaa//", resultEspece[0]);
    setListEspece(resultEspece);
    //
  };
  useEffect(() => {
    fetchUser();
    fetchEspece();
    if (isUpdate) {
      const statutValue =
        dataStatutDemandeIdentification.find((i) => i.name === selectedData?.status)?.label ?? "";
      setStatut(statutValue);
    }
  }, []);
  // console.log("aaaa", propsDataConfig);
  const checkForm = async (EspeceEffectif) => {
    if (demande.user) {
      let result;
      const data = {
        status: demande.status ?? "INITIALIZED",
        id: demande?.id,
        user: demande.user,
        effectifs: EspeceEffectif,
      };
      if (isUpdate) {
        console.log("edit demande ", data);
        result = await updateDemandeIdentification(ApiService.putData, data);
      } else {
        /* const data = {
          status: demande.status ?? "INITIALIZED",
          // userId: demande?.user?.id,
          user: demande.user,
          effectifs: EspeceEffectif,
        }; */
        console.log("new demande ", data);
        result = await createDemandeIdentification(ApiService.postData, [data]);
      }
      console.log("aaaa", result);
      if (result !== ERROR) {
        const loadData = {
          isAlert: true,
          message: "Opération effectuée avec succès",
          isLoading: false,
          alertSeverity: "success",
        };
        handleLoad(loadData);
        setTimeout(() => {
          const lastPath = localStorage.getItem(LAST_PATH);
          navigate(lastPath);
          // navigate(PATH_DEMANDE_IDENTIFICATION);
        }, 200);
      }
    } else {
      const loadData = {
        isAlert: true,
        message: "Veuillez sélectionner un Demandeur",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    }
  };
  // console.log("ddddd", demande?.user);
  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      <Box textAlign="center">
        <Typography variant="h6">Informations de la Demande d&apos;identification</Typography>
      </Box>
      {listUser && !isUpdate && (
        <AutoCompleteComponent
          options={listUser}
          name="user"
          label="Eleveur / Propriétaire"
          value={demande?.user ?? ""}
          onChange={handleInputChange}
          isResultObject
          // isDisabled={user !== undefined}
        />
      )}
      {demande.user && <AnimalPerson person={userInfo} />}
      {listEspece.length > 0 && (
        <>
          <Box textAlign="center">
            <Typography variant="h6">Effectifs par espèces</Typography>
          </Box>

          <EspeceCount
            list={listEspece}
            handleLoad={handleLoad}
            checkForm={checkForm}
            handleInputChange={handleInputChange}
            selectedData={selectedData}
            isUpdate={isUpdate}
            statut={statut}
          />
        </>
      )}
    </Box>
  );
};
const EspeceCount = ({
  list,
  handleLoad,
  checkForm,
  handleInputChange,
  selectedData,
  isUpdate,
  statut,
}) => {
  const [rows, setRows] = useState(list);

  /* const [rows, setRows] = useState([
    { name: "Groupe A", effectif: 0 },
    { name: "Groupe B", effectif: 0 },
    { name: "Groupe C", effectif: 0 },
  ]); */
  // console.log("ddssss", rows[0]);
  // console.log("ddssss///", list[0]);
  const handleEffectifChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].count = parseInt(value, 10) || 0;
    setRows(updatedRows);
  };

  const handleSubmit = () => {
    const effectifTotal = rows.reduce((total, espece) => total + espece.count, 0);
    console.log("Tableau récupéré:", effectifTotal);
    if (effectifTotal === 0) {
      const loadData = {
        isAlert: true,
        message: "Veuillez saisir un effectif",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    } else if (isUpdate && statut === "") {
      const loadData = {
        isAlert: true,
        message: "Veuillez sélectionner un statut",
        isLoading: false,
        alertSeverity: "error",
      };
      handleLoad(loadData);
    } else {
      const especeEffectif = rows
        .filter((i) => i.count > 0)
        .map((i) => ({
          // espece: { codeEspece: i.espece.codeEspece },
          espece: i.espece,
          count: i.count,
          // especeId: i.id,
        }));
      checkForm(especeEffectif);
    }
  };
  const canAddbyAdminDepartement = isAdminDepartemental();
  const canAddbySuperAdmin = isSuperAdmin();
  console.log("dddd", canAddbyAdminDepartement, canAddbySuperAdmin);

  return (
    <TableContainer component={Paper} style={{ width: "100%" }}>
      <Table style={{ width: "100%" }}>
        {/* <TableHead>
          <TableRow>
            <TableCell style={{ width: "70%" }}>Espèces</TableCell>
            <TableCell style={{ width: "30%", textAlign: "right" }}>Effectifs</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow key={row.espece?.label}>
              <TableCell component="th" scope="row" style={{ width: "70%" }}>
                {row.espece?.label}
              </TableCell>
              <TableCell align="right" style={{ width: "30%" }}>
                <TextField
                  type="number"
                  value={row.count}
                  onChange={(e) => handleEffectifChange(index, e.target.value)}
                  variant="outlined"
                  size="small"
                  disabled={isUpdate}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        style={{ marginTop: "16px" }}
      >
        Récupérer le Tableau
      </Button> */}
      {dataStatutDemandeIdentification && Object.keys(selectedData).length !== 0 && (
        <AutoCompleteComponent
          options={dataStatutDemandeIdentification}
          name="status"
          label="statut"
          value={statut ?? ""}
          onChange={handleInputChange}
          isResultObject={false}
          // isDisabled={user !== undefined}
        />
      )}
      {(canAddbyAdminDepartement || canAddbySuperAdmin) && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <MDButton type="submit" variant="gradient" color="info" onClick={handleSubmit}>
            {isUpdate ? "Modifier" : "Enregistrer"}
          </MDButton>
        </Box>
      )}
    </TableContainer>
  );
};

export default NewDemandeIdentificationComponent;
NewDemandeIdentificationComponent.propTypes = {
  propsDataConfig: PropTypes.object.isRequired,
};

EspeceCount.defaultProps = {
  selectedData: {},
  statut: "",
};
EspeceCount.propTypes = {
  list: PropTypes.object.isRequired,
  handleLoad: PropTypes.func.isRequired,
  checkForm: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  // demande: PropTypes.object,
  isUpdate: PropTypes.bool.isRequired,
  statut: PropTypes.string,
};
