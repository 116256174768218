import { ERROR } from "constant/message";
import { MODULE_TAG } from "constant/moduleInfo";
import useArrayOperation from "hooks/useArrayOperation";

const { sortArray } = useArrayOperation();

const getTag = async (getData) => {
  const fetchData = await getData(MODULE_TAG);
  let result = [];
  if (fetchData !== ERROR) {
    result = sortArray(fetchData, "name");
  }
  return result;
};
export default getTag;
