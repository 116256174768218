import React, { useMemo, useState, useEffect } from "react";
import { useLocation /* , useNavigate */ } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";
import DemandeIdentificationDetailsComponent from "layouts/gestionAnimaux/component/DemandeIdentificationDetailsComponent";
// import { LIST_ESPECE_ELEVAGE, LIST_ESPECE_COMPAGNIE } from "constant/espece";
import getEspece from "services/especeService";
import {
  IMG_ESPECE_BOVIN,
  ESPECE_BOVIN,
  ESPECE_CAPRIN,
  IMG_ESPECE_CAPRIN,
  ESPECE_OVIN,
  IMG_ESPECE_OVIN,
  ESPECE_PORCIN,
  IMG_ESPECE_PORCIN,
  ESPECE_AULACODE,
  IMG_ESPECE_AULACODE,
  ESPECE_LAPIN,
  IMG_ESPECE_LAPIN,
  ESPECE_POULET_CHAIR,
  IMG_ESPECE_POULET_CHAIR,
  ESPECE_PONDEUSE,
  IMG_ESPECE_PONDEUSE,
  ESPECE_CHIEN,
  IMG_ESPECE_CHIEN,
  ESPECE_CHAT,
  IMG_ESPECE_CHAT,
  ESPECE_CHEVAL,
  IMG_ESPECE_CHEVAL,
} from "constant/espece";

function DetailDemandeIdentification() {
  // const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedData } = state;
  // console.log("dddd", selectedData);
  const {
    isAlert,
    isLoading,
    message,
    alertSeverity,
    handleLoad,
    getData /* , putData, postData */,
  } = servicesAPI();
  const [title, setTitle] = useState("");
  const [dataDemande, setDataDemande] = useState({});
  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  /* const propsDataConfig = useMemo(() => {
    const value = {
      selectedData,
      niveau,
      ApiService: { getData, putData, postData },
      handleLoad,
    };
    return value;
  }, [selectedData, niveau]); */
  // console.log("ddd", state);
  const setData = (listEspece) => {
    // const listEspece = [...LIST_ESPECE_ELEVAGE, ...LIST_ESPECE_COMPAGNIE];

    const fullEspeceData = selectedData.effectifs.map((i) => {
      // let prevCountEspece = 0;
      const countEspece = 0;
      const dataEspece = listEspece.find((x) => x?.nomCourant === i.espece?.nomCourant);
      let img = "";
      if (i.espece?.nomCourant === ESPECE_BOVIN) {
        img = IMG_ESPECE_BOVIN;
      } else if (i.espece?.nomCourant === ESPECE_CAPRIN) {
        img = IMG_ESPECE_CAPRIN;
      } else if (i.espece?.nomCourant === ESPECE_OVIN) {
        img = IMG_ESPECE_OVIN;
      } else if (i.espece?.nomCourant === ESPECE_PORCIN) {
        img = IMG_ESPECE_PORCIN;
      } else if (i.espece?.nomCourant === ESPECE_AULACODE) {
        img = IMG_ESPECE_AULACODE;
      } else if (i.espece?.nomCourant === ESPECE_LAPIN) {
        img = IMG_ESPECE_LAPIN;
      } else if (i.espece?.nomCourant === ESPECE_POULET_CHAIR) {
        img = IMG_ESPECE_POULET_CHAIR;
      } else if (i.espece?.nomCourant === ESPECE_PONDEUSE) {
        img = IMG_ESPECE_PONDEUSE;
      } else if (i.espece?.nomCourant === ESPECE_CHIEN) {
        img = IMG_ESPECE_CHIEN;
      } else if (i.espece?.nomCourant === ESPECE_CHAT) {
        img = IMG_ESPECE_CHAT;
      } else if (i.espece?.nomCourant === ESPECE_CHEVAL) {
        img = IMG_ESPECE_CHEVAL;
      }
      const item = {
        ...i,
        ...dataEspece,
        prevCount: i.count,
        realCount: countEspece,
        img,
        // data: dataEspece?.counts ?? [],
      };

      /* if (dataEspece !== undefined) {
        item = {
          ...item,
          ...dataEspece,
        };
      } */
      return item;
    });
    const demande = {
      ...selectedData,
      effectifs: fullEspeceData,
    };

    setDataDemande(demande);
    // console.log("demande", demande);
  };
  const fetchEspeceData = async () => {
    const data = await getEspece(getData);
    setData(data);
  };
  useEffect(() => {
    // setData();
    fetchEspeceData();
    if (selectedData) {
      setTitle("Détails Demande d'Identification");
    } else {
      setTitle("Création d'une Demande d'Identification");
    }
  }, [selectedData]);
  return (
    <>
      <ModuleTitle title={title} />
      <BackButton />
      <DemandeIdentificationDetailsComponent demande={dataDemande} />
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailDemandeIdentification;
