import React, { useEffect } from "react";
import { TextField, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const AnimalPerson = ({ person }) => {
  useEffect(() => {}, []);

  return (
    <Box
      sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2, margin: 2 }}
      style={{ backgroundColor: "white" }}
    >
      {person.type && (
        <Box textAlign="center">
          <Typography variant="h6">Informations {person.type}</Typography>
        </Box>
      )}

      <TextField
        label="Nom"
        name="nom"
        value={person?.info?.nom ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Prenom"
        name="prenom"
        value={person?.info?.prenom ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Numéro de téléphone"
        name="numTel"
        value={person?.info?.numTel ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Commune"
        name="commune"
        value={person?.info?.lieu?.commune ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Département"
        name="departement"
        value={person?.info?.lieu?.departement ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
      <TextField
        label="Région"
        name="region"
        value={person?.info?.lieu?.region ?? "Non renseigné"}
        fullWidth
        margin="normal"
        disabled
      />
    </Box>
  );
};

export default AnimalPerson;
AnimalPerson.propTypes = {
  person: PropTypes.object.isRequired,
};
