import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import { Alert, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const LoadComponent = ({ loadData }) => {
  const { isAlert, isLoading, message, alertSeverity, handleLoad } = loadData;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isLoading || isAlert) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isLoading, isAlert]);
  const handleClose = () => {
    setOpen(false);
    const loadD = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "error",
    };
    handleLoad(loadD);
  };
  return (
    <Dialog open={open} fullWidth>
      <MDBox mt={3} mb={1} textAlign="center" sx={{ alignContent: "center" }}>
        {Boolean(isLoading) && <CircularProgress />}
        {Boolean(isAlert) && (
          <Alert
            variant="outlined"
            severity={alertSeverity}
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {message}
          </Alert>
        )}
      </MDBox>
    </Dialog>
  );
};
LoadComponent.defaultProps = {
  loadData: {
    isAlert: false,
    message: "",
    isLoading: false,
    alertSeverity: "",
    handleLoad: () => {},
  },
};
LoadComponent.propTypes = {
  loadData: PropTypes.shape({
    isAlert: PropTypes.bool,
    message: PropTypes.string,
    isLoading: PropTypes.bool,
    alertSeverity: PropTypes.string,
    handleLoad: PropTypes.func,
  }),
};
export default LoadComponent;
