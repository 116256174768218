import DataTableAction from "components/DataTableRow/dataTableAction";
import DataTableContent from "components/DataTableRow/dataTableContent";
import { useState } from "react";
import { tableCellTextFormat } from "utils/textOperation";

export default function useTableRaceMenu() {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionOption, setActionOption] = useState("");
  const tableColumns = [
    { Header: "Nom Courant", accessor: "nomCourant", align: "center" },
    { Header: "Espece", accessor: "espece", align: "center" },
  ];
  const tableRows = (item) => {
    /* const descriptionEspece =
      item.description.length > 30 ? `${item.description.substring(0, 30)}...` : item.description; */
    const row = {
      nomCourant: <DataTableContent title1={tableCellTextFormat(item?.nomCourant, 15)} />,
      espece: <DataTableContent title2={tableCellTextFormat(item?.espece?.nomCourant, 50)} />,
    };
    return row;
  };

  const onSelectedRow = (item, action) => {
    // console.log("onSelectedRow", item, action);
    setSelectedItem(item);
    setActionOption(action);
  };
  const renderDataTable = (listData) => {
    const arrData = [];
    if (listData.length > 0) {
      /* const columnsActions = [{ Header: "action", accessor: "action", align: "center" }];

      setColumns([...tableColumns, ...columnsActions]); */
      setColumns(tableColumns);

      listData.map((i, idx, arr) => {
        let item = {};
        item = tableRows(i);
        arrData.push({
          ...item,
          ...{ action: <DataTableAction handleAction={onSelectedRow} item={i} /> },
        });
        if (idx === arr.length - 1) {
          setRows(arrData);
          return "success";
        }
        return "over";
      });
    } else {
      setRows(arrData);
      setColumns([]);
    }
  };
  return {
    /* formConfigEspece: formConfig,
    validateFormEspece: validateForm,
    tableColumnsEspece: tableColumns,
    tableRowsEspece: tableRows, */
    renderDataTable,
    rows,
    columns,
    selectedItem,
    actionOption,
    onSelectedRow,
  };
}
