// src/context/UserContext.js
import React, { createContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  SUPER_ADMIN_ROLE,
  ADMIN_PAYS_ROLE,
  // ELEVEUR,
  // PROPRIETAIRE,
  ADMIN_DEPARTEMENTAL_ROLE,
  ADMIN_REGION_ROLE,
} from "constant/role";

import CurrentUserRole from "utils/currentUserRole";

// Création du contexte
export const UserContext = createContext();

export const UserProvider = ({ propsDataConfig, children }) => {
  const [user, setUser] = useState(propsDataConfig.selectedData);
  const allowedRoles = [
    SUPER_ADMIN_ROLE,
    ADMIN_PAYS_ROLE,
    ADMIN_DEPARTEMENTAL_ROLE,
    ADMIN_REGION_ROLE,
  ];
  // const [user, setUser] = useState(propsDataConfig.selectedData);
  const canUpdate = allowedRoles.some((role) => CurrentUserRole()?.includes(role));
  /* const isUserEleveur = user?.roles.some(
    (x) => x.roleName === ELEVEUR || x.roleName === PROPRIETAIRE
  );
  if (
    (isUserEleveur ||
      propsDataConfig.roleTitle === "Eleveur" ||
      propsDataConfig.roleTile === "Propriétaire" ||
      propsDataConfig.roleTile === "Agent Identificateur") &&
    isAdminDepartemental()
  ) {
    canUpdate = true;
  } */

  const updateUserDetails = (updatedDetails) => {
    setUser((prevUser) => ({ ...prevUser, ...updatedDetails }));
  };

  const updateUserRole = (newRole) => {
    setUser((prevUser) => ({ ...prevUser, role: newRole }));
  };
  const contextValue = useMemo(() => {
    const value = {
      user,
      niveau: propsDataConfig.niveau,
      roleTitle: propsDataConfig.roleTitle,
      updateUserDetails,
      updateUserRole,
      ApiService: propsDataConfig.ApiService,
      handleLoad: propsDataConfig.handleLoad,
      canUpdate,
    };
    return value;
  }, [user]);

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

// Validation des props avec PropTypes
UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  propsDataConfig: PropTypes.object.isRequired,
};
