import { SUCCESS, EMPTY_VALUE, ERROR } from "constant/message";

import { useState } from "react";
import { ACTION_EDIT, ACTION_ADD } from "constant/dataTableActionConstant";
import { INPUT_COMPONENT, AUTO_COMPLETE_COMPONENT } from "constant/formComponentName";
import useArrayOperation from "hooks/useArrayOperation";
import { MODULE_ESPECE } from "constant/moduleInfo";

function useFormRaceMenu() {
  const { sortArray } = useArrayOperation();
  const [isOpenForm, setisOpenForm] = useState(false);
  const [errorForm, setErrorForm] = useState({});
  const [valuesForm, setValuesForm] = useState({});
  const [titleForm, setTitleForm] = useState("");
  const handleFormState = (state) => {
    setisOpenForm(state);
  };
  const formRace = (selectedItem, listEspece) => {
    const formConfig = [
      {
        name: "nomCourant",
        label: "Nom Courant *",
        initialValue: selectedItem.nomCourant,
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "codeRace",
        label: "Code *",
        initialValue: selectedItem?.codeRace,
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "description",
        label: "Description",
        initialValue: selectedItem?.description,
        component: INPUT_COMPONENT,
        isVisible: true,
        isdisabled: false,
      },
      {
        name: "espece",
        label: "Espece *",
        initialValue: "",
        component: AUTO_COMPLETE_COMPONENT,
        list: listEspece,
        isVisible: true,
        isdisabled: false,
      },
    ];
    return formConfig;
  };
  const getEspece = async (getData) => {
    try {
      const fetchData = await getData(MODULE_ESPECE);
      let result = [];
      if (fetchData !== ERROR) {
        result = sortArray(fetchData, "nomCourant");
      }
      return result;
    } catch (error) {
      console.log("useferme get Lieu");
      throw error;
    }
  };
  const formConfigRace = async (selectedItem, getData) => {
    const listEspece = await getEspece(getData);
    return formRace(selectedItem, listEspece);
  };
  const validateFormRace = (name, value) => {
    // console.log("validateFormRole", name, value, value.length);
    let state = false;
    if (
      (name === "nomCourant" ||
        name === "nomLatin" ||
        // name === "description" ||
        name === "codeRace") &&
      value === ""
    ) {
      state = false;
    } else if (name === "espece" && Object.keys(value).length === 0) {
      state = false;
    } /*  else if (name === "categorieRaceList" && Object.keys(value).length === 0) {
      state = false;
    }  */ else {
      state = true;
    }
    return state;
  };
  const valuesFormInitialization = (formConfig, actionOption) => {
    console.log("start");
    try {
      const initialValues = {};
      // const initialErrors = {};
      formConfig.forEach((config) => {
        initialValues[config.name] = config.initialValue || "";
        // initialErrors[config.name] = "";
      });
      setErrorForm({});
      setValuesForm(initialValues);
      if (actionOption === ACTION_ADD) {
        setTitleForm("Nouvelle Race");
      } else if (actionOption === ACTION_EDIT) {
        setTitleForm("Détails");
      }
    } catch (error) {
      console.log("valuesFormInitialization Race error", error);
    }
  };

  const handleChangeFormValues = (event) => {
    try {
      const { name, value } = event.target;
      let data = {
        ...valuesForm,
        [name]: value,
      };
      if (name === "categorieEspeceList") {
        data = {
          ...data,
          especeCategory: value.label,
        };
      }
      setValuesForm(data);
    } catch (error) {
      console.log("handleChangeFormValues Espece error", error);
      alert(error);
    }
  };
  const validateForm = (formConfig) => {
    let data = "";
    try {
      const values = {};
      formConfig.forEach((config, idx, arr) => {
        if (config.isVisible) {
          const checkValue = validateFormRace(config.name, valuesForm[config.name]);

          if (checkValue) {
            if (config.name in values) {
              delete values[config.name];
            }
          } else {
            values[config.name] = EMPTY_VALUE;
            // console.log("checkValue", values);
          }
        }
        if (idx === arr.length - 1) {
          // console.log("innnn", values);
          // handleAction();
          if (Object.keys(values).length === 0) {
            data = SUCCESS;
          }
        }
      });
      setErrorForm(values);
    } catch (error) {
      console.log("validateForm error", error);
    }
    return data;
  };
  return {
    handleFormState,
    isOpenForm,
    valuesFormInitialization,
    handleChangeFormValues,
    errorForm,
    valuesForm,
    setValuesForm,
    setErrorForm,
    validateForm,
    titleForm,
    formConfigRace,
    validateFormRace,
  };
}

export default useFormRaceMenu;
