import React, { useMemo, useState, useEffect } from "react";
import { UserProvider } from "context/userContext";
import UserProfile from "layouts/gestionUsers/component/UserProfile";
import { useLocation } from "react-router-dom";
import BackButton from "components/BackButton";
import servicesAPI from "services/servicesAPI";
import LoadComponent from "components/LoadComponent";
import ModuleTitle from "components/ModuleTitle";

function DetailUser() {
  const { state } = useLocation();
  const { selectedData, niveau, roleTitle } = state;
  // console.log("dddd", selectedData);
  const { isAlert, isLoading, message, alertSeverity, handleLoad, getData, putData, postData } =
    servicesAPI();
  const [title, setTitle] = useState("");
  const loadDataConfig = useMemo(() => {
    const value = {
      isAlert,
      isLoading,
      message,
      alertSeverity,
      handleLoad,
    };
    return value;
  }, [isAlert, isLoading, message, alertSeverity]);
  const propsDataConfig = useMemo(() => {
    const value = {
      selectedData,
      niveau,
      roleTitle,
      ApiService: { getData, putData, postData },
      handleLoad,
    };
    return value;
  }, [selectedData, niveau]);
  // console.log("ddd", state);
  useEffect(() => {
    if (selectedData) {
      setTitle(`Détails Compte Utilisateur ${roleTitle}`);
    } else {
      setTitle(`Création Compte Utilisateur ${roleTitle}`);
    }
  }, [selectedData]);
  return (
    <>
      <ModuleTitle title={title} />
      <BackButton />

      <UserProvider propsDataConfig={propsDataConfig}>
        <UserProfile />
      </UserProvider>
      <LoadComponent loadData={loadDataConfig} />
    </>
  );
}

export default DetailUser;
