// Récupère les rôles actuels de l'utilisateur
import { ERROR } from "constant/message";
import { SUPER_ADMIN_ROLE } from "constant/role";

const { USER_DATA_STORAGE } = require("constant");

const userInfos = () => {
  try {
    const userDataStorage = localStorage.getItem(USER_DATA_STORAGE);
    let userInfo = {};
    if (userDataStorage !== null) {
      userInfo = JSON.parse(userDataStorage);
    }
    return userInfo;
  } catch (error) {
    // console.log("userInfos error", error);
    return ERROR;
  }
};

const CurrentUserRole = () => {
  const userInfo = userInfos();
  // if (userInfo && userInfo.roles) {
  if (userInfo?.roles) {
    return userInfo.roles.map((role) => role.roleName);
  }
  return [];
};

export default CurrentUserRole;
export const isSuperAdmin = () => {
  return CurrentUserRole().some((role) => role === SUPER_ADMIN_ROLE);
};
