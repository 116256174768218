import { ERROR } from "constant/message";
import { MONTH_NAMES } from "constant";

const dateFormat = (date) => {
  try {
    const dateF = ` ${String(date.getDate()).padStart(2, "0")}/${
      MONTH_NAMES[date.getMonth()]
    }/${date.getFullYear()}`;
    return dateF;
  } catch (error) {
    console.log("dateFormat eror", error);
    return ERROR;
  }
};
export default dateFormat;
export const formatDateYMD = (date) => {
  return date ? new Date(date).toISOString().split("T")[0] : ""; // Format YYYY-MM-DD
};
