import { FormHelperText } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MDTypography from "components/MDTypography";
import "./Calendar.css";
// import { makeStyles } from "@mui/styles";

function Index_DatePicker({
  dateValue,
  label,
  handleDate,
  isError,
  error,
  module,
  name,
  disabled,
}) {
  // function Index_DatePicker({ label }) {
  // const [date_value, set_date_value] = useState(new Date());
  const handleChange = (value) => {
    // set_date_value(value);
    handleDate(value);
  };

  return (
    <div /* style={{ marginLeft: 0, marginRight: 0, width: 480 }} */>
      <MDTypography display="block" variant="button" fontWeight="regular">
        {label}
      </MDTypography>
      <DatePicker
        selected={dateValue}
        onChange={(date) => {
          const data = {
            target: {
              name,
              value: date,
            },
          };
          handleChange(data, module);
        }}
        // classes={{ root: classes.datePickerStyles }}
        dateFormat="dd/MM/yyyy"
        // className={{}}
        calendarClassName="custom-calendar"
        wrapperClassName="datePicker full-width"
        disabled={disabled}
      />
      {isError && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
}

export default Index_DatePicker;
Index_DatePicker.propTypes = {
  label: PropTypes.string,
  // value: PropTypes.string,
  dateValue: PropTypes.instanceOf(Date),
  handleDate: PropTypes.func,
  isError: PropTypes.bool,
  error: PropTypes.string,
  module: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
Index_DatePicker.defaultProps = {
  label: "",
  dateValue: new Date(),
  // value: "",
  handleDate: () => {},
  isError: false,
  error: "",
  module: "",
  name: "",
  disabled: true,
};
